<template>
  <div style="position: relative">
    <nav
      class="navbar"
      :class="{ 'nav-float': navbarWorkFloatMode }"
      ref="navbarWork"
    >
      <ul class="navbar-nav">
        <li class="nav-item active">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'window' }"
          >
            <svg class="icon icon-small architecture-window-1 icon-with-text">
              <use xlink:href="/images/icon-lib.svg#architecture-window-1" /></svg
            >Janelas
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'guardhouse' }"
          >
            <svg class="icon icon-small camping-shelter icon-with-text">
              <use xlink:href="/images/icon-lib.svg#camping-shelter" /></svg
            >Guaritas
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'lift' }"
          >
            <svg class="icon icon-small lift icon-with-text">
              <use xlink:href="/images/icon-lib.svg#lift" /></svg
            >Elevadores
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'door' }"
          >
            <svg class="icon icon-small dresser-double-door-1 icon-with-text">
              <use xlink:href="/images/icon-lib.svg#dresser-double-door-1" /></svg
            >Portas e Divisórias
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'roof' }"
          >
            <svg class="icon icon-small modern-architecture-buildings icon-with-text">
              <use xlink:href="/images/icon-lib.svg#modern-architecture-buildings" /></svg
            >Tetos e Paredes
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'glass' }"
          >
            <svg class="icon icon-small appliances-fridge icon-with-text">
              <use xlink:href="/images/icon-lib.svg#appliances-fridge" /></svg
            >Eletrodomésticos
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'showcase' }"
          >
            <svg class="icon icon-small show-theater icon-with-text">
              <use xlink:href="/images/icon-lib.svg#show-theater" /></svg
            >Vitrines
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-color-red-500"
            :to="{ name: 'balcony' }"
          >
            <svg class="icon icon-small buildings-1 icon-with-text">
              <use xlink:href="/images/icon-lib.svg#buildings-1" /></svg
            >Sacadas
          </router-link>
        </li>
      </ul>
    </nav>

    <CoolLightBox
      :items="photos"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    ></CoolLightBox>

    <!--         totalPhotos: {{ totalPhotos }} <br>
        photos.length: {{ photos.length }} <br>
        data: {{ data }} <br>
        start: {{ start }} <br> -->

    <!-- busy: {{ busy }} -->

    <section id="work">
      <div class="container-fluid">

        <!--  <div
          class="row row-photos"          
        > -->
        <div class="container" v-if="pageError && !localLoader">
          <PageAlert imgName = "img-erro.svg">
            Houve um <strong>erro inesperado </strong> ao carregar as fotos. Tente
      novamente mais tarde.
          </PageAlert>          
        </div>

        <div v-if="localLoader" class="loader-local">
          <Loading  />
        </div>

        

          <div
            class="row row-photos"
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="20"
          >
            
            <div
              class="col-photos col-lg-4 col-md-6 col-sm-12 col-fullhd col-extra-large"              
              v-for="(photo, photoIndex) in photos"
              :key="photoIndex"
              @click="index = photoIndex"
            >
              <Photo
                :src="photo.thumb"
                :title="photo.title"
                :description="photo.description"
                :idLocation="photo.idLocation"
              />
            </div>

            <div v-if="localLoaderMore" class="col-photos col">
              <div class="loader-local">
                <Loading />
              </div>
            </div>

          </div>        


          <QuotationCall
              position="fixedAways"
          />

      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import PhotosService from "@/services/photos/photos-service.js";
import Photo from "@/components/photo/photo.vue";
import Loading from "@/components/shared/loading.vue";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const PageAlert = () =>
  import(
    /* webpackChunkName: "PageAlert" */ "@/components/shared/alert/page-alert.vue"
  );

const QuotationCall = () => import(/* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue");

export default {
  components: {
    CoolLightBox,
    Photo,
    PageAlert,
    Loading,
    QuotationCall,
  },

  data() {
    return {
      navbarWorkFloatMode: false,
      photos: [],
      index: null,
      busy: true,
      data: [],
      pageError: false,
      localLoader: true,
      localLoaderMore: false,

      totalPhotos: 0,
      start: 0,
      limit: 20,

      titleWork : [
        {
          type : 'window' , 
          title : 'Película e Adesivo em Janelas',        
          description : 'Instalação de películas e adesivos em janelas para proteção solar, privacidade e decoração'
        },
        {
          type : 'guardhouse' , 
          title : 'Película em Guaritas',          
          description : 'Aplicação de películas em guaritas para privacidade, redução do calor e proteção anti impactos'
        },
        {
          type : 'lift' , 
          title : 'Película e Adesivo em Elevadores',        
          description : 'Envelopamento de elevadores com películas e adesivos para modernização, proteção e decoração'
        },
        {
          type : 'door' , 
          title : 'Película e Adesivo em Portas e Divisórias',        
          description : 'Instalação de películas e adesivos em Portas e Divisórias para privacidade, redução do calor e luminozidade'
        },
        {
          type : 'roof' , 
          title : 'Película e Adesivo em Tetos e Paredes',        
          description : 'Aplicação de películas e adesivos em Tetos e Paredes para decoração, privacidade e proteção solar'
        },
        {
          type : 'glass' , 
          title : 'Película e Adesivo em Eletrodomésticos',        
          description : 'Envelopamento de eletrodomésticos com películas e adesivos pra revitalização e decoração'          
        },
        {
          type : 'showcase' , 
          title : 'Película em Vitrines',        
          description : 'Instalação de películas em Vitrines para proteção solar e conservação de bens e produtos'
        },
        {
          type : 'balcony' , 
          title : 'Película em Varandas e Sacadas',        
          description : 'Instalação de películas em Varandas e Sacadas para reduzir o calor e proteger os móveis do sol'
        },
      ]
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    /* this.$emit(`update:title`, "Nossos Trabalhos");
    this.$emit(
      `update:introduction`,
      "Contamos com uma equipe de profissionais especializados e dedicados proporcionando qualidade e satisfação "
    ); */
    this.changeTitleDescription();
    this.$emit(`update:bgHeader`, "red");
    //console.log('created');
    
    //console.log(this.$myGlobalCheckCreated.layoutInternal, 'this.$myGlobalCheckCreated.layoutInternal - work')

    

    if(this.$myGlobalCheckCreated.layoutInternal === 'created') {
      this.makeQuery();
    }
  },

  mounted: function () {
    // console.log('mounted');
    window.addEventListener("scroll", this.checkNavbarWorkFloatMode);
  },

  methods: {

    changeTitleDescription: function() {

      const routeName = this.$route.name;

      if (routeName === "work") {
        this.$emit(`update:title`, "Nossos Trabalhos");
        this.$emit(
          `update:introduction`,
          "Contamos com uma equipe de profissionais especializados e dedicados proporcionando qualidade e satisfação "
        );
      } else {
        let objTitleByRouteName = this.titleByRouteName(this.$route.name);
        this.$emit(`update:title`, objTitleByRouteName.title);
        this.$emit(`update:introduction`, objTitleByRouteName.description);
      }
    },

    makeQuery: function () {
      if (this.$route.name === "work") {
        this.listPhotos();
      } else {
        this.listPhotosLocationId();
      }      
    },

    listPhotos: function () {
      let self = this;
      self.photos = [];
      self.busy = true;
      self.localLoader = true;

      PhotosService.listPhotos(self.start, self.limit)
        .then(function (response) {
          self.photos = response.data[0].data.map((item) => {
            return {
              title: item.title,
              description: item.description,
              idLocation: item.id_location,
              src: item.patch + item.name,
              thumb: item.patch + "small/" + item.name,
            };
          });          
          self.totalPhotos = response.data[0].totalPhotos;
          self.start = self.photos.length;
          self.busy = false;
          self.pageError = false;
          self.localLoader = false;
        })
        .catch(function (error) {
          self.pageError = true;
          self.localLoader = false;
          console.log(error);
        });
    },
    listPhotosLocationId: function () {
      let self = this;
      self.photos = [];      
      self.localLoader = true;
      let locationId = this.locationIdByRouteName();

      PhotosService.listPhotosLocationId(self.start, self.limit, locationId)
        .then(function (response) {
          //self.photos = response.data;
          self.photos = response.data[0].data.map((item) => {
            return {
              title: item.title,
              description: item.description,
              idLocation: item.id_location,
              src: item.patch + item.name,
              thumb: item.patch + "small/" + item.name,
            };
          });
          self.totalPhotos = response.data[0].totalPhotos;
          self.start = self.photos.length;
          self.busy = false;
          self.pageError = false;
          self.localLoader = false;
        })
        .catch(function (error) {
          self.pageError = true;
          self.localLoader = false;
          console.log(error);
        });
    },

    loadMore: function () {
      
      if(this.$myGlobalCheckCreated.layoutInternal === 'not-created')  return;
      //console.log("loadmore");

      let self = this;
      self.localLoaderMore = false;

      if (self.checkLoadMore(self.photos.length)) {
        self.localLoaderMore = true;
        self.start = self.photos.length;
        self.busy = true;

        let promise =
          this.$route.name === "work"
            ? PhotosService.listPhotos(self.start, self.limit)
            : PhotosService.listPhotosLocationId(
                self.start,
                self.limit,
                this.locationIdByRouteName()
              );

        promise
          .then(function (response) {
            let loadMoreRecords = response.data[0].data.map((item) => {
              return {
                title: item.title,
                description: item.description,
                idLocation: item.id_location,
                src: item.patch + item.name,
                thumb: item.patch + "small/" + item.name,
              };
            });

            // Adiciona os itens em photos
            loadMoreRecords.forEach(function (item) {
              self.photos.push(item);
            });

            setTimeout(() => {              
              self.busy = false;
              console.log ('busy false')
            }, 1000);
            self.pageError = false;
            self.localLoaderMore = false;

            //self.busy = false;
          })
          .catch(function (error) {
            console.log(error);
            self.busy = true;
            self.pageError = true;
            self.localLoaderMore = false;
          });
      }
    },

    checkLoadMore: function (photosOnPage) {
      return photosOnPage < this.totalPhotos ? true : false;
    },

    locationIdByRouteName: function () {
      const routeName = this.$route.name;
      return routeName === "lift"
        ? 2
        : routeName === "window"
        ? 1
        : routeName === "guardhouse"
        ? 3
        : routeName === "glass"
        ? 4
        : routeName === "roof"
        ? 5
        : routeName === "door"
        ? 6
        : routeName === "balcony"
        ? 7
        : routeName === "showcase"
        ? 8
        : 0;
    },

    titleByRouteName: function (typeWork) {
      return this.titleWork.find( item => item.type === typeWork );
    },

    checkNavbarWorkFloatMode: function () {
      let elTopPosition = this.$refs.navbarWork.getBoundingClientRect().top;

      if (elTopPosition <= 85) {
        this.navbarWorkFloatMode = true;
      } else {
        this.navbarWorkFloatMode = false;
      }
    },
    positionToMove(e) {
      event.stopPropagation();
      const el = e.target;
      return el;
      //console.log(el);
    },
  },

  destroyed: function () {
    window.removeEventListener("scroll", this.checkNavbarWorkFloatMode);
  },

  watch: {
    $route() {
      this.start = 0;
      this.makeQuery();
      this.changeTitleDescription();
      this.localLoaderMore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
.navbar {
  position: sticky;
  z-index: 11;
  top: 61px;
  overflow-x: auto;

  @media (min-width: $screenLarge) {
    top: 84px;
  }
}
.navbar-nav {
  display: flex;
  list-style: none;
  font-size: $BodyText3;
}
.nav-item {
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 140px;
}
.nav-link {
  display: block;
  padding: 0.388rem 1rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;

  background-color: lighten($red-500, 40%);

  border: 2px solid $white;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
}
.nav-link:hover,
.nav-link.router-link-exact-active {
  background-color: $red-500;
  color: $white;
}

.nav-float .nav-link {
  color: $graffiti-900;
  background-color: lighten($graffiti-900, 70%);
}
.nav-float .nav-link:hover,
.nav-float .nav-link.router-link-exact-active {
  background-color: $black-900;
  color: $white;
}

#work {
  //margin-bottom: 2rem;

  .container-fluid {
    padding: 0 2px;
  }

  .row-photos {
    height: 100%;
    min-height: 100%;
    margin-right: -2px;
    margin-left: -2px;
  }

  .col-photos {
    padding-right: 1px;
    padding-left: 1px;
    padding-bottom: 2px;
    background-color: $white;
  }
}

@media (min-width: 1920px) {
  .col-fullhd {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 2250px) {
  .col-extra-large {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 2750px) {
  .col-extra-large {
    -ms-flex: 0 0 16.6666%;
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  }
}
@media (min-width: 3200px) {
  .col-extra-large {
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }
}
.loader-local {
  position: relative;
  height: 300px;  
}

</style>